<template>
   <v-container fluid class="bg234 pa-0">
      <v-row class="justify-space-around">
        <v-col
        cols="12" sm="8" md="4"
          class="order-1 order-md-0 text-center "
        >
          <v-avatar size="100">
            <img src="@/assets/login/call-center.png" />
          </v-avatar>
          <p
              style="font-family: kelly slab !important;"
              class="text-subtitle2 letras"
            >
              <br />Somos a Easycob,<br />
              uma empresa com mais de <br />
              26 anos de atuação na área <br />
              de recuperação de crédito.
              <br /><br />
              Trabalhamos com diversos parceiros,<br /> 
              incluindo empresas de telefonia,<br />
              água, internet, saneamento, entre outros.<br /><br />
              Estamos com ótimas ofertas para você <br />
              quitar seus débitos com descontos.
            </p>
        </v-col>
        <v-col
          class="text-center align-center justify-center order-0 order-md-1 "
          cols="12" sm="8" md="4"
        >
          <img
            src="@/assets/login/easycob.svg"
            alt="Logo da Easycob"
            width="230px"
            class="pt-2"
          />
          <v-card class="rounded-xl mx-6" >
            <div class="text-subtitle1 font-weight-light py-10" style="color: #2899D6;">
              Aproveite a oportunidade de negociar seu débito! <br />
              Informe seu <strong>CPF</strong> ou <strong>CNPJ</strong> abaixo
              para ver a oferta.
            </div>
            <v-card-actions>
              <v-row no-gutters>
                <v-text-field
                  placeholder="CPF/CNPJ"
                  outlined
                  v-model="login"
                ></v-text-field>
                <v-btn dark x-large color="primary" @click="enviar"> 
                  <v-icon large>mdi-arrow-right-bold</v-icon> 
                </v-btn>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
</template>

<script>
import { api } from "@/conf/api";
/*
import {
  pegarParcXML,
  pegarBoletoXML,
  //  datavencimento,
} from "@/conf/utilitario";
*/
//import utilidade from "@/conf/utilitario";
import moment from "moment";
export default {
  name: "OfertaLogin",
  data() {
    return {
      cpf: "",
      cgc: "",
      contrato: "",
      login: "",
      tipo: "",
      mostra: false,
      carteira: "",
    };
  },
  mounted() {
    this.cgc = this.$route.params.cgc;
    this.cpf = this.$route.params.cpf;
    this.contrato = this.$route.params.contrato;
    this.tipo = this.$route.params.tipo;
    //console.log("tipo", this.tipo, this.tipo.toLowerCase());
    if (this.tipo.toLowerCase() !== "c") {
      this.$router.push({ name: "ErroPage" });
    }

    if (
      //04164616000158
      this.cgc != "33000118000190" &&
      this.cgc != "33000118000183" &&
      this.cgc != "04164616000158" &&
      this.cgc != "04164616000160" &&
      this.cgc != "33000118000179" &&
      this.cgc != "33000118000181" &&
      this.cgc != "05423963000111" &&
      this.cgc != "05423963000114" &&
      this.cgc != "71208516000174" &&
      this.cgc != "27001440000110" 
    ) {
      this.$router.push({ name: "ErroPage" });
    }
    if (
      this.cgc == "33000118000190" ||
      this.cgc == "33000118000183" ||
      this.cgc == "04164616000158" ||
      this.cgc == "04164616000160" ||
      this.cgc == "33000118000179" ||
      this.cgc == "33000118000181" ||
      this.cgc == "05423963000111" ||
      this.cgc == "05423963000114"
    ) {
      this.carteira = "oinova";
    }
    if (this.cgc == "71208516000174") {
      this.carteira = "algar";
    }
    if (this.cgc == "27001440000110") {
      this.carteira = "viasat";
    }
  },
  methods: {
    datavencimento2(v) {
      let dataagora = new Date(v + " 00:00:00");
      // dataagora.setDate(dataagora.getDate() + 3);

      if (dataagora.getDay() == 0) {
        dataagora.setDate(dataagora.getDate() + 1);
      }
      if (dataagora.getDay() == 6) {
        dataagora.setDate(dataagora.getDate() + 2);
      }
      let dia = dataagora
        .getDate()
        .toString()
        .padStart(2, "0");
      let mes = (dataagora.getMonth() + 1).toString().padStart(2, "0");
      let ano = dataagora.getFullYear().toString();
      return `${ano}-${mes}-${dia}`;
    },
    acertaMoney(value) {
      return parseFloat(value)
        .toFixed(2)
        .replace(".", ",");
    },
    acertaData(d) {
      let dia = d.substr(0, 2);
      let mes = d.substr(3, 2);
      let ano = d.substr(6, 4);
      return `${ano}-${mes}-${dia}`;
    },
    async enviar() {
      //console.log( this.carteira)
      this.mostra = true;
      this.$store.commit("zeraMessage");
      let cpf = this.login.replace(/\D/gi, "");
      //console.log(cpf);
      if (cpf != this.cpf || !cpf) {
        this.$store.commit("showMessage", {
          text: "Sua senha está errada!",
          color: "warning",
          show: true,
        });
        this.mostra = false;
        this.login = "";
        return;
      }
      await api.post("Logs/inserecpflog",{cpf})
      this.$store.dispatch("insereCPF", cpf);
      if (this.carteira == "oinova") return await this.pegaOiNova();
      if (this.carteira == "algar") return await this.pegaAlgar();
      if (this.carteira == "viasat") return await this.pegaViasat();
    },
    async pegaViasat(){
      try{
        let res = await api.get(
          `oferta/boleto/${this.cgc}/${this.cpf}/${this.contrato}`
        );

        let boleto = res.data.dados;
        console.log('dados viasat',boleto);
        this.$store.dispatch("marcaDividaAtual", boleto.cli[0]);

        let datarem = boleto.cli[0].cpdatrem.substr(0, 10);
        console.log("rem", datarem);
        let datavenc = moment(boleto.venc.substr(0, 10)).format();
        let datavenc2 = this.datavencimento2(boleto.venc.substr(0, 10));
        let datahoje = new Date();
       // console.log("datas", boleto.venc, datavenc, datahoje);
        if (datavenc < datahoje) {
          this.$store.commit("showMessage", {
            text: "Erro de data! Ligue pra Easycob",
            color: "warning",
            show: true,
          });
          this.mostra = false;
          this.login = "";
          return;
        }
        this.$store.dispatch("insereDividas2", boleto.cli);
        await this.$store.dispatch("marcaPacoteAtual", "O-VIASAT");
          // await this.$store.dispatch("marcaNegocAtual", item);
          await this.$store.dispatch("marcaVencAtual", datavenc2);
         this.$store.dispatch("insereCPF", this.cpf);
         /*
        await this.$store.dispatch("marcaDividaAtual", {
          cpcpfcgc: this.cpf,
          contrato: this.contrato,
          cpcgc: this.cgc,
          venc: datavenc2,
        });
        */
        this.$router.push({ name: "NegocViasat" });

      }catch(e){
        console.log("messageeee", e.message);
        this.$store.commit("showMessage", {
          text: "Erro de boleto! Ligue pra Easycob",
          color: "warning",
          show: true,
        });
        this.mostra = false;
        this.login = "";
        return;

      }finally {
        this.mostra = false;
      }
    },
    async pegaAlgar() {
      try {
        let res = await api.get(
          `oferta/boleto/${this.cgc}/${this.cpf}/${this.contrato}`
        );

        let boleto = res.data.dados;
        console.log(boleto);

        let datarem = boleto.cli[0].cpdatrem.substr(0, 10);
        console.log("rem", datarem);
        let datavenc = moment(boleto.venc.substr(0, 10)).format();
        let datavenc2 = this.datavencimento2(boleto.venc.substr(0, 10));
        let datahoje = new Date();
        console.log("datas", boleto.venc, datavenc, datahoje);
        if (datavenc < datahoje) {
          this.$store.commit("showMessage", {
            text: "Erro de data! Ligue pra Easycob",
            color: "warning",
            show: true,
          });
          this.mostra = false;
          this.login = "";
          return;
        }
        
          
        await this.$store.dispatch("marcaDividaAtual", {
          cpcpfcgc: this.cpf,
          contrato: this.contrato,
          cpcgc: this.cgc,
          venc: datavenc2,
        });
        
        this.$router.push({ name: "PlanoAlgarOferta" });
      } catch (e) {
        console.log("messageeee", e.message);
        this.$store.commit("showMessage", {
          text: "Erro de boleto! Ligue pra Easycob",
          color: "warning",
          show: true,
        });
        this.mostra = false;
        this.login = "";
        return;
      } finally {
        this.mostra = false;
      }
    },
    async pegaOiNova() {
      try {
        let res = await api.get(
          `oferta/boleto/${this.cgc}/${this.cpf}/${this.contrato}`
        );

        let boleto = res.data.dados;
        //console.log(boleto.cli.cpdatrem.substr(0, 10));

        let datarem = boleto.cli[0].cpdatrem.substr(0, 10);

        let datavenc = moment(boleto.venc.substr(0, 10)).format();
        let datahoje = new Date();
        console.log(boleto.venc, datavenc, datahoje);
        if (datavenc < datahoje) {
          this.$store.commit("showMessage", {
            text: "Erro de data! Ligue pra Easycob",
            color: "warning",
            show: true,
          });
          this.mostra = false;
          this.login = "";
          return;
        }
        //------------------------------------------------------------------
        let url = `telemanova/oi/cpfcontrato/${this.cpf}/${this.contrato}/${this.cgc}/${datarem}/site`;
        // console.log("urlll", url);

        let resposta = await api.get(url);
        // console.log("resp oi", resposta.data.dados);
        let dividaOiNova = resposta.data;
        if (dividaOiNova.status != "200") throw new Error("Oooops2!");
        this.$store.dispatch("insereDividaOiNova", dividaOiNova);
        // this.$router.push("/planooinova");
        let divida = this.$store.state.divida_oi_nova;
        let consolidado = [];
        divida.dados.items.forEach((a) => {
          // console.log("tipo", a.registerType);

          if (a.registerType == "2" || a.registerType == "3") {
            if (a.numberOfInstallments == "0") {
              consolidado.push(a);
            }
          }
        });
        if (consolidado.length == 0) {
          return (window.location.href = "https://www.oi.com.br/negociacao/");
        }
        let item = consolidado[0];
        let id = item.registerId;
        let emailemail = await api.get(`pessoa/email/${this.cpf}`);
        let email2 = emailemail.data.dados;
        console.log("email", email2.length);
        let meuEmail = "";
        if (email2.length > 0) {
          meuEmail = email2[0].dmmail;
        } else {
          meuEmail = window.prompt("Coloque um email...");
          if (!meuEmail) {
            window.alert("Você precisa colocar um email...");
            return;
          }
        }

        await this.$store.dispatch("marcaEmail", meuEmail);
        let body = {
          register: id,
          tipoContato: "EMAIL",
          email: meuEmail,
          telefone: "",
        };
        url = `telemanova/oi/oferta/${this.cpf}/${this.contrato}/${this.cgc}/${datarem}/site`;
        resposta = await api.post(url, body);
        // console.log("rep", resposta);
        let resp = resposta.data;
        if (resp.status != "200") throw new Error("Oooops3!");
        this.$store.dispatch("insereDividaOiNovaResp", {
          item,
          resp,
          venc: boleto.venc.substr(0, 10),
        });
        this.$router.push({ name: "BolOiAVista" });
      } catch (e) {
        console.log("messageeee", e.message);
        this.$store.commit("showMessage", {
          text: "Erro de boleto! Ligue pra Easycob",
          color: "warning",
          show: true,
        });
        this.mostra = false;
        this.login = "";
        return;
      } finally {
        this.mostra = false;
      }
    },
  },
};
</script>

<style scoped>

.letras {
  font-size: 18px;
  color: white;
  padding: 20px;
  border-radius: 25px;
  text-shadow: 1px 1px 1px black;
}
.bg234 {
  background: url("../../assets/login/fundo.png");
  background-size: cover;
  height: calc(100vh + 450px);
  background-color: #2899d6;
}

.ctn-apresentação {
  font-size: 18px;
  color: white;
  padding: 20px;
  border-radius: 25px;
  text-shadow: 1px 1px 1px black;
  width: 300px !important;
}
.img-card2 {
  top: 5px;
  box-shadow: 1px 1px 20px 1px rgba(0, 0, 0, 0.3);
  height: 100px;
  border-radius: 50px 50px 50px 50px;
  background-color: rgb(255, 255, 255);
}
.ctn-img-card {
  height: 533px !important;
  padding: 20px;
}
.parte2 {
  color: #2899d6;
}

</style>
