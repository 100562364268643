<template>
  <v-container fluid class="bg234 pa-0">
      <v-row class="justify-space-around">
        <v-col
        cols="12" sm="8" md="4"
          class="order-1 order-md-0 text-center "
        >
          <v-avatar size="100">
            <img src="@/assets/login/call-center.png" />
          </v-avatar>
          <p
              style="font-family: kelly slab !important;"
              class="text-subtitle2 letras"
            >
              <br />Somos a Easycob,<br />
              uma empresa com mais de <br />
              26 anos de atuação na área <br />
              de recuperação de crédito.
              <br /><br />
              Trabalhamos com diversos parceiros,<br /> 
              incluindo empresas de telefonia,<br />
              água, internet, saneamento, entre outros.<br /><br />
              Estamos com ótimas ofertas para você <br />
              quitar seus débitos com descontos.
            </p>
        </v-col>
        <v-col
          class="text-center align-center justify-center order-0 order-md-1 "
          cols="12" sm="8" md="4"
        >
          <img
            src="@/assets/login/easycob.svg"
            alt="Logo da Easycob"
            width="230px"
            class="pt-2"
          />
          <v-card class="rounded-xl mx-6" >
            <div class="text-subtitle1 font-weight-light py-10" style="color: #2899D6;">
              Aproveite a oportunidade de negociar seu débito! <br />
              Informe seu <strong>CPF</strong> ou <strong>CNPJ</strong> abaixo
              para ver a oferta.
            </div>
            <v-card-actions>
              <v-row no-gutters>
                <v-text-field
                  placeholder="CPF/CNPJ"
                  outlined
                  v-model="login"
                ></v-text-field>
                <v-btn dark x-large color="primary" @click="enviar"> 
                  <v-icon large>mdi-arrow-right-bold</v-icon> 
                </v-btn>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
</template>

<script>
import { api } from "@/conf/api";
//import utilidade from "@/conf/utilitario";
import moment from "moment";
export default {
  name: "OfertaCpfLogin",
  data() {
    return {
      cpf: "",

      login: "",
      tipo: "",
      mostra: false,
      carteira: "",
    };
  },
  mounted() {
    this.cpf = this.$route.params.cpf;
    this.tipo = this.$route.params.tipo;
    //console.log("tipo", this.tipo, this.tipo.toLowerCase());
    if (this.tipo.toLowerCase() !== "c") {
      this.$router.push({ name: "ErroPage" });
    }
  },
  methods: {
    async enviar() {
      this.mostra = true;
      this.$store.commit("zeraMessage");
      let cpf = this.login.replace(/\D/gi, "");
      //console.log(cpf);
      if (cpf != this.cpf || !cpf) {
        this.$store.commit("showMessage", {
          text: "Sua senha está errada!",
          color: "warning",
          show: true,
        });
        this.mostra = false;
        this.login = "";
        return;
      }
      await api.post("Logs/inserecpflog",{cpf})
      this.$store.dispatch("insereCPF", cpf);
      this.pegaOiNova();
    },
    async pegaOiNova() {
      try {
        let res = await api.get(`oferta/boleto/oi/${this.cpf}/oi`);
        //console.log("cli", res);
        let boleto = res.data.dados;
        // console.log("cli", boleto);

        // let datarem = boleto.cli[0].cpdatrem.substr(0, 10);
        // console.log("rem", datarem, boleto.cli);

        let datavenc = moment(boleto.venc.substr(0, 10)).format();
        let datahoje = new Date();
        console.log(boleto.venc, datavenc, datahoje);
        if (datavenc < datahoje) {
          this.$store.commit("showMessage", {
            text: "Erro de data! Ligue pra Easycob",
            color: "warning",
            show: true,
          });
          this.mostra = false;
          this.login = "";
          return;
        }
        if (!boleto.cli || boleto.cli.length == 0)
          throw new Error("sem dados cli");
        //------------------------------------------------------------------
        let consolidado = [];
        let divida = [];
        // esse for é das dívidas
        for (let i = 0; i < boleto.cli.length; i++) {
          let temOferta = false;
          let item = boleto.cli[i];
          // console.log("cli", boleto.cli[i]);
          let cgc = item.cpcgc;
          let contrato = item.cpcontrato;
          let valorDividaContrato = item.cpsaldev;
          let datarem = item.cpdatrem.substr(0, 10);
          let url = `telemanova/oi/cpfcontrato/${this.cpf}/${contrato}/${cgc}/${datarem}/site`;
          let resposta = await api.get(url);
          let dividaOiNova = resposta.data;
          if (dividaOiNova.status != "200") throw new Error("Oooops2!");
          dividaOiNova.dados.items.forEach((a) => {
            // console.log("tipo", a.registerType);

            if (a.registerType == "2" || a.registerType == "3") {
              if (a.numberOfInstallments == "0") {
                temOferta = true;
                consolidado.push({
                  item: a,
                  cgc,
                  contrato,
                  cpf: this.cpf,
                  datarem,
                  venc: datavenc,
                  valorDivida: valorDividaContrato,
                  valorDividaAPI: a.originalTotalValue,
                });
              }
            }
          });

          if (!temOferta) {
            divida.push({
              item: {},
              cgc,
              contrato,
              cpf: this.cpf,
              datarem,
              venc: datavenc,
              valorDivida: valorDividaContrato,
              valorDividaAPI: dividaOiNova.dados.items[0].contractTotalValue,
            });
          }
          temOferta = false;
        }
        //if (consolidado.length == 0) {
        //  return (window.location.href = "https://www.oi.com.br/negociacao/");
        // }
        // console.log("cons", consolidado);
        // console.log("divida", divida);
        this.$store.dispatch("insereDividaOiNova", consolidado);
        this.$store.dispatch("insereDividaOiNova2", divida);
        return this.$router.push({ name: "PlanoOiNovaCPF" });

        //-----------------------------------------
      } catch (e) {
        console.log("messageeee", e.message);
        this.$store.commit("showMessage", {
          text: "Erro de boleto! Ligue pra Easycob",
          color: "warning",
          show: true,
        });
        this.mostra = false;
        this.login = "";
        return;
      } finally {
        this.mostra = false;
      }
    },
  },
};
</script>

<style scoped>
.letras {
  font-size: 18px;
  color: white;
  padding: 20px;
  border-radius: 25px;
  text-shadow: 1px 1px 1px black;
}
.bg234 {
  background: url("../../assets/login/fundo.png");
  background-size: cover;
  height: calc(100vh + 450px);
  background-color: #2899d6;
}

.ctn-apresentação {
  font-size: 18px;
  color: white;
  padding: 20px;
  border-radius: 25px;
  text-shadow: 1px 1px 1px black;
  width: 300px !important;
}
.img-card2 {
  top: 5px;
  box-shadow: 1px 1px 20px 1px rgba(0, 0, 0, 0.3);
  height: 100px;
  border-radius: 50px 50px 50px 50px;
  background-color: rgb(255, 255, 255);
}
.ctn-img-card {
  height: 533px !important;
  padding: 20px;
}
.parte2 {
  color: #2899d6;
}
</style>
